import React, {useContext, useState} from "react";
import UserContext from "../common/UserContext";

//components
import {LicensePreview} from "./RequestDownload/LicensePreview";
import {LicenseDownload} from "./RequestDownload/LicenseDownload";

//multilingual
import {
    requestLicenseSummaryKey,
    mainMessages, myAssignedProductsKey,
} from "../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";



const RequestDownload = (props) => {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const {
        stepperItems,
        setStepperItems,
        handleBeforeUnload,
        changeStepActivation,
        setIsLoading,
        assignedProducts,
        setAssignedProducts,
        headerInfo,
        setHeaderInfo,
        selectedProducts,
        setSelectedProducts,
        futureSubPoolValues,
        setFutureSubPoolValues,
        fromRequestAgain,
    } = props

    const localization = useLocalization();
    const [requestedLicense, setRequestedLicense] = useState(false)
    const [requestedProducts, setRequestedProducts] = useState([])

    return (
        <>
            <div
                className={'k-h4'}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(requestLicenseSummaryKey, mainMessages[siteLanguageDefault][requestLicenseSummaryKey])}
            </div>

            <div
                className={'k-h4'}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(myAssignedProductsKey, mainMessages[siteLanguageDefault][myAssignedProductsKey])}
            </div>

            {!requestedLicense && (
                <LicensePreview
                    changeStepActivation={changeStepActivation}
                    setIsLoading={setIsLoading}
                    assignedProducts={assignedProducts}
                    setAssignedProducts={setAssignedProducts}
                    setRequestedLicense={setRequestedLicense}
                    setRequestedProducts={setRequestedProducts}
                />
            )}

            {requestedLicense && (
                <LicenseDownload
                    stepperItems={stepperItems}
                    setStepperItems={setStepperItems}
                    handleBeforeUnload={handleBeforeUnload}
                    changeStepActivation={changeStepActivation}
                    setIsLoading={setIsLoading}
                    assignedProducts={assignedProducts}
                    setAssignedProducts={setAssignedProducts}
                    requestedProducts={requestedProducts}
                    setRequestedProducts={setRequestedProducts}
                    headerInfo={headerInfo}
                    setHeaderInfo={setHeaderInfo}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    futureSubPoolValues={futureSubPoolValues}
                    setFutureSubPoolValues={setFutureSubPoolValues}
                    fromRequestAgain={fromRequestAgain}
                />
            )}
        </>
    )
}

export default RequestDownload